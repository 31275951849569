import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "./App.css";
import "./Anti.css";
import TaskPage from "./pages/TaskPage/index";
import EmptyPage from "./pages/emptyPage/index";
import EditTaskPage from "./pages/AddTaskPage";
import LoginPage from "./pages/loginPage";
import QRcodePage from "./pages/QRcodePage";

import Layout from "./Layouts";
import Navbar from "./Layouts/navbar";
import AddNewTask from "./pages/AddNewTask";
import TaskGroup from "./pages/TaskGroup";
import EditTaskGroup from './pages/AddtaskGroup'
import AddQuiz from './pages/Quiz'
import AddQuizEducation from './pages/AqqQuizEducation'
import Ranking from './pages/Ranking'
import EditTaskGroups from './pages/EdittaskGroups/EditTaskGroup'
import EditQuiz from './pages/EditQuiz'
import Updatetask from './pages/UpdatTask'
function App() {
  const [token, setToken] = useState(false);

  useEffect(() => {
    let tkn = localStorage.getItem("token");
    if (tkn) setToken(true);
  }, []);
  return (
    // !token ?
    <Router>
      
        <Routes>
          {/* <Route path="/" element={<EmptyPage />} /> */}
          <Route path="/" element={<LoginPage />} />
          <Route path="/add_task" element={<Layout><EditTaskPage /></Layout>} />
          <Route path="/TaskPage" element={<Layout><TaskPage /></Layout>} />
          <Route path="/empty" element={<EmptyPage />} />
          <Route path="/addTask" element={<Layout><AddNewTask/></Layout>} />
          <Route path="/taskgroup" element={<Layout><TaskGroup/></Layout>} />
          <Route path="/edit_taskgroups/:itemId" element={<Layout><EditTaskGroups/></Layout>} />
          <Route path="/add_taskgroup" element={<Layout><EditTaskGroup/></Layout>} />
          <Route path="/quiz" element={<Layout><AddQuiz/></Layout>} />
          <Route path="/addquiz" element={<Layout><AddQuizEducation/></Layout>} />
          {/* <Route path="/ranking" element={<Layout><Ranking/></Layout>} /> */}
          <Route path="/edit_task/:itemId" element={<Layout><Updatetask/></Layout>} />
          <Route path="/edit_quiz/:itemId" element={<Layout><EditQuiz/></Layout>} />
        </Routes>
    </Router>
    // <Router>
    //     <Routes>
    //       <Route path='/' element = {<LoginPage />} />
    //       <Route path='/login' element={<LoginPage />}/>
    //       <Route path='/2FA' element={<QRcodePage />}/>
    //     </Routes>
    // </Router>
  );
}

export default App;
