import React, { useEffect, useState } from "react";
import { Card } from "antd";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Snackbar, Alert } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import img from "../../assets/mark1.png";
import editIcon from "../../assets/edit-svgrepo-com 1.svg";
import deleteicon from "../../assets/bin-with-lid 1.svg";
import "./index.css";
import { deleteTaskGroups } from "../../apis/deleteTaskGroup.js";
import Header from "./header";
import DeleteModal from "../../components/DeleteModal/index.jsx";
import Loading from "../../components/loading";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ScreenLoader from "../../components/ScreenLoader/ScreenLoader.js";
import { displayNumbers } from "../../lib/tools";

import { getTasks, deleteTask } from "../../apis/tasks.js";
import { getTaskGroups } from "../../apis/getTaskGroup.js";

const TaskList = [
  {
    img: "taskIcons/Play_Store.png",
    title: "PlayStore",
    description: "Download the Bluemoon App",
    completetion: 15,
    rewards: 100000,
    started_Date: "1/11/2023",
    ended_Date: "24/11/2023",
    id: "34234234234",
    type: 0,
  },
];

const TaskPage = () => {
  const goTo = useNavigate();

  const [selectedTaskButton, setSelectedTaskButton] = useState(0);
  const [selectedLive, setSelectedLive] = useState(0);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [currentItem, setCurrentItem] = useState();
  const [taskList, setTaskList] = useState([{}]);
  const [taskGroup, setTaskGroup] = useState();

  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarStatus, setSnackbarStatus] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const handleNewTaskButtonClick = () => {
    setSelectedTaskButton(0);
  };

  const handleEssentialTaskButtonClick = () => {
    setSelectedTaskButton(1);
  };

  const handleBonusTaskButtonClick = () => {
    setSelectedTaskButton(2);
  };

  const handleLiveButtonClick = () => {
    setSelectedLive(0);
  };

  const handleEndedButtonClick = () => {
    setSelectedLive(1);
  };

  // const deleteItem = async (itemId) => {
  //   let res = await deleteTask(taskList[itemId].id);
  //   if (res.success === false) {
  //     setSnackbarMessage(res.message);
  //     setSnackbarStatus("warning");
  //     setIsOpenSnackbar(true);
  //   } else {
  //     setTaskList(taskList.filter((item, key) => key !== itemId));
  //     setSnackbarMessage("Deleted Successfully!");
  //     setSnackbarStatus("success");
  //     setIsOpenSnackbar(true);
  //   }
  // };
  const handleEditButtonClick = (itemId) => {
    console.log("itemId", itemId);
    goTo(`/edit_taskgroups/${itemId}`);
  };

  const handledeleteTask = async (id) => {
    try {
      const data = await deleteTaskGroups(id);
      console.log("data--------------");

      if (data.success) {
        setTaskGroup((prevTaskGroup) => ({
          ...prevTaskGroup,
          mission: prevTaskGroup.mission.filter((item) => item._id !== id),
        }));
        toast.success("Task Group deleted successfully!");
      } else {
        console.error("Failed to delete task:", data.message);
        toast.error("Failed to delete task: " + data.message);
      }
    } catch (error) {
      console.error("Error deleting task:", error);
      toast.error("Error deleting task: " + error.message);
    }
  };

  const handleDeleteButtonClick = (itemId) => {
    setOpenDeleteModal(true);
    setCurrentItem(itemId); // Set the current item ID for deletion
  };

  const deleteItem = (itemId) => {
    handledeleteTask(itemId); // Call your delete logic
  };

  // useEffect(() => {
  //   const run = async () => {
  //     setIsLoading(true);
  //     let is_live = selectedLive === 0 ? true : false;
  //     let task_type =
  //       selectedTaskButton === 0
  //         ? "new"
  //         : selectedTaskButton === 1
  //         ? "essential"
  //         : "bonus";
  //     let res = await getTasks(task_type, is_live);
  //     if (res.success) {
  //       console.log(res);
  //       let missions = res.missions;
  //       setTaskList(
  //         missions?.map((mission) => ({
  //           img: `https://storage.cloud.google.com/bluemoon_task_images//${mission.photoUrl}?authuser=0`,
  //           title: mission.title,
  //           description: mission.description,
  //           completetion: mission.task_complete,
  //           rewards: mission.bonus,
  //           started_Date: mission.createdAt.split("T")[0],
  //           ended_Date:
  //             mission.date_ended === undefined ? "-" : mission.date_ended,
  //           link: mission.link,
  //           id: mission._id,
  //         }))
  //       );
  //       setSnackbarMessage("Loaded Successfully!");
  //       setSnackbarStatus("success");
  //       setIsOpenSnackbar(true);
  //     } else {
  //       setSnackbarMessage("Load faild!");
  //       setSnackbarStatus("warning");
  //       setIsOpenSnackbar(true);
  //     }
  //     setIsLoading(false);
  //   };
  //   run();
  // }, [selectedLive, selectedTaskButton]);

  useEffect(() => {
    const fetchTaskGroup = async () => {
      setIsLoading(true);
      try {
        const data = await getTaskGroups();
        setTaskGroup(data);
        console.log("task groups  Data:", data);
      } catch (error) {
        console.error("Error fetching task groups:", error);
      }
      setIsLoading(false);
    };
    fetchTaskGroup();
  }, []);
  return (
    // <Layout>
    <>
      {/* <Loading disp={isLoading}/> */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={isOpenSnackbar}
        autoHideDuration={6000}
        onClose={() => {
          setIsOpenSnackbar(false);
        }}
      >
        DeleteM
        <Alert
          onClose={() => {
            setIsOpenSnackbar(false);
          }}
          severity={snackbarStatus}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        deleteCurrentItem={() => deleteItem(currentItem)}
      />
      <div className="landing-contain-content">
        <Header />
        <div className="landing-contain-section">
          {/* <Card
            // title="Card title"
            bordered={false}
            style={{
              // width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginBottom: "10px",
              marginTop:'30px',
              background: '#101214',
            
            }}
          >
            <div className="tasks-button">
              <button
                className={selectedTaskButton === 0 ? "selected" : ""}
                onClick={handleNewTaskButtonClick}
              >
              <span className="tasks-text text-gradient">New Tasks</span>
              </button>
              <button
                className={selectedTaskButton === 1 ? "selected" : ""}
                onClick={handleEssentialTaskButtonClick}
              >
               <span className="tasks-text text-gradient">Essential Tasks</span>
              </button>
              <button
                className={selectedTaskButton === 2 ? "selected" : ""}
                onClick={handleBonusTaskButtonClick}
              >
             <span className="tasks-text text-gradient">Bonus Tasks</span>
              </button>
            </div>
            <div className="live-ended">
              <button
                className={selectedLive === 0 ? "selected" : ""}
                onClick={handleLiveButtonClick}
              >
                Live
              </button>
              <button
                className={selectedLive === 1 ? "selected" : ""}
                onClick={handleEndedButtonClick}
              >
                Ended
              </button>
     
            </div>
          </Card> */}
          {isLoading ? (

            <ScreenLoader />

          ) : (

            <TableContainer
              component={Paper}
              style={{
                msOverflowStyle: "none",
                scrollbarWidth: "none",
                backgroundColor: "rgba(0,0,0,1)",
                boxShadow: "none",
                borderRadius: "10px",
              }}
            >
              <Table
                className="for-border"
                sx={{ minWidth: 1000, backgroundColor: "#101214" }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontFamily: "Montserrat-Medium",
                        color: "#D7D8D9",
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "15px",
                        borderBottom: "1px solid #24262B",
                      }}
                    >
                      {" "}
                      Description
                    </TableCell>
                    <TableCell
                      style={{
                        fontFamily: "Montserrat-Medium",
                        color: "#D7D8D9",
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "15px",
                        borderBottom: "1px solid #24262B",
                      }}
                    >
                      Name
                    </TableCell>
                    {/* <TableCell style={{fontFamily:'InterMedium',color:'#D7D8D9',fontWeight:'500',fontSize:'14px',lineHeight:'15x',borderBottom:'1px solid #24262B'}}>Rewards</TableCell> */}
                    <TableCell
                      style={{
                        fontFamily: "Montserrat-Medium",
                        color: "#D7D8D9",
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "15px",
                        borderBottom: "1px solid #24262B",
                      }}
                    >
                      Date Started
                    </TableCell>
                    <TableCell
                      style={{
                        fontFamily: "Montserrat-Medium",
                        color: "#D7D8D9",
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "15px",
                        borderBottom: "1px solid #24262B",
                      }}
                    >
                      Date Ended
                    </TableCell>
                    <TableCell
                      style={{
                        fontFamily: "Montserrat-Medium",
                        color: "#D7D8D9",
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "15px",
                        borderBottom: "1px solid #24262B",
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {taskGroup?.mission?.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell className="tablecell-container">
                        <h4
                          style={{
                            fontFamily: "Montserrat-Bold",
                            fontWeight: "700",
                            fontSize: "16px",
                            color: "#FFFFFF",
                            lineHeight: "24px",
                            margin: "0px",
                          }}
                        >
                          {item?.groupName}
                        </h4>
                        <p
                          style={{
                            fontFamily: "Montserrat",
                            fontWeight: "400",
                            fontSize: "14px",
                            color: "#D7D8D9",
                            lineHeight: "21px",
                            margin: "0px",
                          }}
                        >
                          {item?.groupDescription}
                        </p>
                      </TableCell>
                      <TableCell
                        className="tablecell-container"
                        style={{
                          fontFamily: "Montserrat-Medium",
                          fontWeight: "500",
                          fontSize: "14px",
                          color: "#D7D8D9",
                          lineHeight: "21px",
                        }}
                      >
                        {item?.groupName}
                      </TableCell>
                      {/* <TableCell className="tablecell-container" style={{fontFamily:'Inter',fontWeight:'400',fontSize:'14px',color:'#F4F6F7',lineHeight:'21px'}}>{displayNumbers(task.rewards)}</TableCell> */}
                      <TableCell
                        className="tablecell-container"
                        style={{
                          fontFamily: "Montserrat-Medium",
                          fontWeight: "500",
                          fontSize: "14px",
                          color: "#D7D8D9",
                          lineHeight: "21px",
                        }}
                      >
                        {item?.createdAt}
                      </TableCell>
                      <TableCell
                        className="tablecell-container"
                        style={{
                          fontFamily: "Montserrat-Medium",
                          fontWeight: "500",
                          fontSize: "14px",
                          color: "#D7D8D9",
                          lineHeight: "21px",
                        }}
                      >
                        {selectedLive === 1 ? item?.updatedAt : ""}
                      </TableCell>
                      <TableCell
                        className="tablecell-container"
                        style={{ padding: "2px" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                            position: "relative",
                            right: "10px",
                          }}
                        >
                          <IconButton
                            aria-label="edit"
                            onClick={() => {
                              handleEditButtonClick(item._id);
                            }}
                          >
                            <img
                              src={editIcon}
                              alt="edit"
                              style={{ width: "22px", height: "22px" }}
                            />
                          </IconButton>
                          <IconButton
                            aria-label="delete"
                            onClick={() => {
                              handleDeleteButtonClick(item?._id);
                            }}
                          >
                            <img
                              src={deleteicon}
                              alt="delete"
                              style={{ width: "22px", height: "22px" }}
                            />
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      </div>
    </>

    // </Layout>
  );
};

export default TaskPage;
