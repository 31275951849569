import React, { useState } from "react";
import { Card } from "antd";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

import MarkImage from "../../assets/mark.png";

import "./index.css";

const LoginPage = () => {
  const goTo = useNavigate();

  // const [showPassword, setShowPassword] = React.useState(false);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSignInButtonClick = () => {
    if (userName == "admin" && password == "admin") {
      localStorage.setItem("token", "bear+tok");
      goTo("/TaskPage");
      window.location.reload();
    } else {
      setUserName("");
      setPassword("");
    }
    // localStorage.setItem("token", "bear+tok");
     goTo("/TaskPage");
    // window.location.reload();
  };

  return (
    <div className="login-page">
      {/* <img src="/Images/logo.svg" alt="/" /> */}
      <Card
        style={{
          width: "574px",
          minHeight: "300px",
          background: "rgba(254,242,246,0.1)",
          border:'none',
          padding: "25px 40px",
        }}
        className="login-page-card"
      >
        <h1 className="sigin-text"
          style={{
            fontFamily: "InterBold",
            color: "#FFFFFF",
            fontSize: "30px",
            fontWeight: "800",
            lineHeight: "28px",
          }}
        >
          Sign In
        </h1>
        <p  className="sigin-text"
      
          style={{
            fontFamily: "InterSemibold",
            color: "#094813",
            fontSize: "16px",
            fontWeight: "600",
            color:'rgba(244,245,247,0.5)',

            lineHeight: "25.6px",
            marginTop: "10px",
          }}
        >
          Enter your credentials to access your account
        </p>

        <div className="form-container">
          <div className="username-form">
            <p className="labeluser">Username</p>
            <input
              className="input-field1"
              type="text"
              id="username"
              value={userName}
              onChange={(e) => {
                setUserName(e.currentTarget.value);
              }}
              placeholder="Enter username"
            />
          </div>
          <div className="username-form1">
            <p className="labeluser">Password</p>
            <div className="password-container">
              <input
                className="input-field2"
                type={showPassword ? "text" : "password"}
                id="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.currentTarget.value);
                }}
                placeholder="Enter password"
              />
              <img
                  src="/Images/eye.png"
                  alt="Show/Hide password"
                  className="eye-icon"
                  onClick={togglePasswordVisibility}
                />
            </div>
          </div>
        </div>

        {/* <label className="label_user">Username</label>
        <TextField 
          
          id="outlined-basic"
           label="Enter username"
          value={userName}
          onChange={(e) => {
            setUserName(e.currentTarget.value);
          }}
          variant="outlined"
          fullWidth
          sx={{
            
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                border: "1px solid #0B6719", // Default border color
              },
              "&:hover fieldset": {
                borderColor: "none", // Border color on hover
              },
              "&.Mui-focused fieldset": {
                border: "1px solid #0B6719", // Border color when focused
              },
            },
            "& input": {
              color: "#3456d0", // Text color inside input
              fontFamily: "Poppins, sans-serif", // Font family for input text
              padding: "12px 15px", // Padding inside the input field
            },
            "& .MuiInputLabel-root": {
              color: "#09481366", // Default label color
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "#09481366", // Label color when focused
            },
          }}
        />
        <FormControl
          sx={{ width: "100%" }}
          style={{
            marginTop: "42px",
            borderRadius: "20px!important",
          }}
          variant="outlined"
        >
            <label className="label_user">Password</label>
          <InputLabel
            htmlFor="outlined-adornment-password"
            
            sx={{
              color: "#09481366", // Change label color
              "&.Mui-focused": {
                color: "#09481366", // Change label color when focused
              },
            }}
          >
         
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            style={{
              color: "#3456d0",
              borderRadius: "12px",
              // border: "1px solid #0B6719",
              marginBottom: "45px",
              height: "50px",
            }}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #0B6719",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "none", // Border color on hover
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #0B6719", // Border color when focused
              },
            }}
            
            value={password}
            onChange={(e) => {
              setPassword(e.currentTarget.value);
            }}
            type={showPassword ? "text" : "password"}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl> */}
        <div className="remember-container">
          <FormControlLabel
            value="end"
            control={
              <Checkbox
                sx={{
                  color: "rgba(255,255,255,0.2)",
                  padding: 0,
                  margin: 0,
                  width: "18px",
                  height: "18px",

                  "&.Mui-checked": {
                    color: "transparent",
                    background: '#1657FF',
                    borderRadius: "0px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "18px",
                    height: "18px",
                    position: "relative",
                    padding: 0,

                    "&:after": {
                      content: "''",
                      position: "absolute",
                      width: "9px",
                      height: "7px",
                      backgroundColor: "white",
                      clipPath:
                        "polygon(14% 44%, 0% 60%, 36% 100%, 100% 25%, 85% 10%, 37% 80%)",
                    },
                    "& .MuiSvgIcon-root": {
                      fontSize: 0,
                    },
                  },
                }}
              />
            }
            label="Remember me"
            labelPlacement="end"
            sx={{
              "& .MuiFormControlLabel-label": {
                fontFamily: "InterMedium",
                color: "#F4F6F7", // Color of the label text
                fontSize: "16px",
                fontWeight: "500",
                letterSpacing: "unset",
                lineHeight: "28px",
                marginLeft: "10px",
                //   fontFamily:"Roboto"
              },
            }}
          />
        </div>
        <Button className="button-login-1"
          variant="contained"
          style={{
            height: "60px",
            width: "100%",
            // marginTop: "50px",
            background: '#1657FF',
            borderRadius: "20px",
            height: "56px",
            fontFamily: "InterMedium",
            fontWeight: "500",
            fontSize: "20px",
            lineHeight: "24.2px",
            textAlign: "center",
            textTransform: "none",
          }}
          onClick={handleSignInButtonClick}
          disableElevation
        >
          Sign In
        </Button>
        {/* <div className="forgot-password">
          <a className="forget-text">Forgot Password?</a>
        </div> */}
      </Card>
    </div>
  );
};

export default LoginPage;
