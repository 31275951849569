import React from "react";
import Lottie from "lottie-react";
import animationData from "./Animation - 1732604502338.json";

function ScreenLoader({ text }) {
  return (
    <div
      className=" d-flex justify-content-center align-items-center"
      style={{
        // zIndex: 9999,
        // top: 0,
        // left: 0,
        // width: "100%",
        // height: "100%",
        // background: "rgba(0, 0, 0, 0.1)",
        // position: "relative",
        height: "51%"
      }}
    >
      <div
        style={{ color: "black" }}
        className="d-flex flex-column align-items-center"
      >
        <Lottie
          animationData={animationData}
          loop={true}
          autoplay={true}
          style={{ width: 200, height: 200 }}
        />
        {/* <h2
          style={{ bottom: "30%", color: "black", }}
          className="text-white mt-5"
        >
          {text}
        </h2> */}
      </div>
    </div>
  );
}

export default ScreenLoader;
