import React, { useEffect, useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { DropzoneArea } from "material-ui-dropzone";
import { useNavigate } from "react-router-dom";
import { Snackbar, Alert } from "@mui/material";
import axios from "axios";
import config from "../../config.js";
import { API_URL } from "../../utils/client.js";
import Loading from "../../components/loading/index.jsx";

import { addNewTask, updateTask } from "../../apis/tasks.js";
import { uploadImages } from "../../apis/uploadImages.js";
import { toast } from 'react-toastify';
import ScreenLoader from "../../components/WholeLoader/ScreenLoader.js";
import "./index.css";
import { addTask } from "../../apis/addTask.js";
import { getTaskGroups } from "../../apis/getTaskGroup.js";
const EditTaskPage = () => {
  const goTo = useNavigate();

  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [rewards, setRewards] = useState();
  const [link, setLink] = useState();
  const [type, setType] = useState("task group");
  const [taskGroups, setTaskGroups] = useState([]);
  const [img, setImg] = useState();
  const [taskId, setTaskId] = useState("");
  const [imageSource, setImageSource] = useState();
  const [taskGoup, setTaskGoup] = useState();
  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarStatus, setSnackbarStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setloading] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState({ id: "", groupName: "" });

  const handleCancelButtonClick = () => {
    goTo("/TaskPage");
  };

  // const handleFileChange = (files) => {
  //   if (files.length > 0) {
  //     setImg(files[0].path);
  //   }
  // };

  const handleUploadImage = async (imageFile) => {
    try {
      const data = await uploadImages(imageFile);
      console.log("Image uploaded successfully:", data.url);
      return data.url;
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error;
    }
  };
  const handleAddTask = async () => {
    if (!title || !description || !rewards || !link || !selectedGroup || !img) {
      toast.error("All fields are required");
      return;
    }
    setloading(true);
    try {
      const formData = new FormData();
      formData.append("file", img);

      const response = await axios.post(`${API_URL}/uploadImages`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(response, "this is response");

      toast.success("Image uploaded successfully!");

      const res = await addTask(
        title,
        description,
        rewards,
        link,
        selectedGroup.groupName,
        response.data.data,
        selectedGroup.id
      );
      console.log("saved data is", res);

      if (res.success === false) {
        toast.warning(res.message);
      } else {
        toast.success(res.message);
      }

      window.location.reload();
    } catch (error) {
      console.error("Upload failed!", error);
      toast.error("Upload failed!");
    }

    console.log("Title:", title);
    console.log("Description:", description);
    console.log("Rewards:", rewards);
    console.log("Link:", link);
    console.log("Selected Group:", selectedGroup);
    console.log("Image URL:", img);
    setloading(false)
    console.log("pashaaaaaaaaaa")
    goTo("/TaskPage");
  };


  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await getTaskGroups();
        console.log("Fetched task groups:", response?.mission);
        setTaskGroups(response?.mission);
      } catch (error) {
        console.error("Error fetching task groups:", error);
      }
    };

    fetchGroups();
  }, []);

  const handleChange = (event) => {
    const selectedItem = taskGroups?.find(
      (item) => item.groupName === event.target.value
    );
    setSelectedGroup({
      id: selectedItem?._id,
      groupName: selectedItem.groupName,
    });
  };
  return (
    <>
      {loading ? (

        <ScreenLoader />

      ) : (

        <>
          <Loading disp={isLoading} />
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={isOpenSnackbar}
            autoHideDuration={6000}
            onClose={() => {
              setIsOpenSnackbar(false);
            }}
          >
            <Alert
              onClose={() => {
                setIsOpenSnackbar(false);
              }}
              severity={snackbarStatus}
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>
          {/* <Layout> */}
          <div style={{ backgroundColor: "#101214" }}>
            <div className="edit-task-page">
              <div className="edit-task-page-header">
                <h2
                  style={{
                    fontFamily: "Montserrat-Bold",
                    fontSize: "24px",
                    fontWeight: "700",
                    color: "#F4F5F7",
                    lineHeight: "29.05px",
                  }}
                >
                  Reward /
                </h2>
                <h4
                  style={{
                    fontFamily: "Montserrat-Medium",
                    fontSize: "16px",
                    fontWeight: 500,
                    color: "#9ea0a5",
                    marginLeft: "5px",
                    marginTop: "5px",
                  }}
                >
                  Add Task
                </h4>
              </div>
              <div
                style={{
                  border: "2px solid #121212",
                  borderRadius: "10px",
                  marginTop: "24px",
                }}
              >
                <div className="edit-task-page-body">
                  <div className="edit-task-page-body-left">
                    <div style={{ marginBottom: "30px" }}>
                      <label
                        style={{
                          fontFamily: "Montserrat-Bold",
                          fontSize: "16px",
                          fontWeight: "700",
                          color: "#FFFFFF",
                          lineHeight: "19.5px",
                        }}
                        htmlFor="task_title"
                      >
                        Task Title
                      </label>
                      <input
                        type="text"
                        className="task_title"
                        value={title}
                        onChange={(e) => {
                          setTitle(e.target.value);
                        }}
                        name="task_title"
                        placeholder="Enter title of the reward"
                      />
                    </div>
                    <div style={{ marginBottom: "33px" }}>
                      <label
                        style={{
                          fontFamily: "Montserrat-Bold",
                          fontSize: "16px",
                          fontWeight: "700",
                          color: "#FFFFFF",
                          lineHeight: "19.5px",
                        }}
                        htmlFor="task_description"
                      >
                        Task Description
                      </label>

                      <textarea
                        className="task_title1"
                        value={description}
                        onChange={(e) => {
                          setDescription(e.target.value);
                        }}
                        name="task_description"
                        // rows="10"
                        // cols="50"
                        placeholder="Enter description of the reward"
                      />

                      <style>
                        {`
      #task_description::placeholder {
        color: rbga(244,245,247,0.5);
      }
    `}
                      </style>
                    </div>
                    <div style={{ marginBottom: "33px" }}>
                      <label
                        style={{
                          fontFamily: "Montserrat-Bold",
                          fontSize: "16px",
                          fontWeight: "700",
                          color: "#FFFFFF",
                          lineHeight: "19.5px",
                        }}
                        htmlFor="reward_amount"
                      >
                        Choose MoonCoin Reward Amount
                      </label>
                      <input
                        className="input-number1"
                        type="number"
                        value={rewards}
                        onChange={(e) => setRewards(e.target.value)}
                        id="reward_amount"
                        name="reward_amount"
                        onWheel={(e) => e.target.blur()}
                        placeholder="Select Skainet Reward Amount"
                      />
                    </div>
                    <div style={{ marginBottom: "36px" }}>
                      <label
                        style={{
                          fontFamily: "Montserrat-Bold",
                          fontSize: "16px",
                          fontWeight: "700",
                          color: "#FFFFFF",
                          lineHeight: "19.5px",
                        }}
                        htmlFor="url_link"
                      >
                        Task URL Link
                      </label>
                      <input
                        className="input-number1"
                        type="url"
                        id="url_link"
                        value={link}
                        onChange={(e) => {
                          setLink(e.target.value);
                        }}
                        name="url_link"
                        placeholder="Input Task URL Link"
                      />
                    </div>
                    <div style={{ marginBottom: "60px" }}>
                      <label
                        style={{
                          fontFamily: "Montserrat-Bold",
                          fontSize: "16px",
                          fontWeight: "700",
                          color: "#FFFFFF",
                          lineHeight: "19.5px",
                        }}
                        htmlFor="task_group"
                      >
                        Choose Task Group
                      </label>
                      <Select
                        id="task_group"
                        value={selectedGroup.groupName}
                        onChange={handleChange}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        sx={{
                          color: "white",
                          ".MuiSelect-icon": { color: "white" },
                          backgroundColor: "#333",
                        }}
                        renderValue={(selected) => {
                          if (!selected) {
                            return (
                              <p
                                className="task__group__placeholder"
                                style={{
                                  fontFamily: "Montserrat-Medium",
                                  fontWeight: "500",
                                  fontSize: "16px",
                                  color: "#24262B)",
                                  marginBottom: "0px",
                                }}
                              >
                                Select Group
                              </p>
                            );
                          }
                          return selected;
                        }}
                      >
                        {taskGroups.length > 0 ? (
                          taskGroups.map((group) => (
                            <MenuItem
                              key={group._id}
                              value={group.groupName}
                              style={{
                                fontFamily: "Montserrat-Semibold",
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#FFFFFF",
                                lineHeight: "19.5px",
                                background: "transparent",
                                borderBottom: "1px solid #50535B",
                              }}
                            >
                              {group.groupName}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem disabled value="">
                            No task groups available
                          </MenuItem>
                        )}
                      </Select>
                    </div>
                    {/* <br /> */}
                    <div className="edit-page-left-button-group">
                      <Button
                        variant="contained"
                        onClick={handleAddTask}
                        disableElevation
                      >
                        {taskId.length ? "Publish" : "Publish"}
                      </Button>
                      <Button variant="outlined" onClick={handleCancelButtonClick}>
                        Cancel
                      </Button>
                    </div>
                  </div>
                  <div className="edit-task-page-body-right">
                    <label
                      style={{
                        fontFamily: "Montserrat-Bold",
                        fontSize: "16px",
                        fontWeight: "700",

                        color: "#FFFFFF",
                      }}
                      htmlFor="upload_image"
                    >
                      Upload Task Image
                    </label>
                    <label className="imagesize">Image size: 52x52</label>
                    <DropzoneArea
                   
                      id="upload_image"
                      filesLimit={1}
                      acceptedFiles={["image/*"]}
                      maxFileSize={5000000}
                      showAlerts={["error", "info"]}
                      dropzoneText="Drag & Drop or"
                      value={img}
                      onChange={(files) => {
                        setImg(files[0]);
                      }}
                      onDropRejected={(files) => {
                        alert("File rejected: " + files[0].name);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </Layout> */}
        </>

      )}
    </>
  );
};

export default EditTaskPage;
