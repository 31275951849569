import React, { useEffect, useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { DropzoneArea } from "material-ui-dropzone";
import { useNavigate } from "react-router-dom";
import { Snackbar, Alert } from "@mui/material";
import axios from "axios";
import config from "../../config";
import { toast } from 'react-toastify';
import ScreenLoader from "../../components/WholeLoader/ScreenLoader.js";
import Loading from "../../components/loading";
import { addQuiz } from "../../apis/addQuiz";

import "./index.css";

const EditTaskPage = () => {
  const goTo = useNavigate();

  const [type, setType] = useState("Education");
  const [question, setQuestion] = useState();
  const [loading, setloading] = useState(false);
  const [options, setOptions] = useState(["", "", "", ""]);
  const [correctAnswer, setCorrectAnswer] = useState();
  const [reward, setReward] = useState(0);
  const [bot, setBot] = useState("T-800");
  const [taskId, setTaskId] = useState("");
  const [isOpenSnackbar, setIsOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarStatus, setSnackbarStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const handleOptionChange = (index, value) => {
    const newOptions = [...options];
    newOptions[index] = value;
    setOptions(newOptions);
  };
  const handleCancelButtonClick = () => {
    goTo("/quiz");
  };

  const handleAddQuiz = async () => {
    setloading(true)
    if (!type || !question || !options || !correctAnswer || !reward) {
      toast.error("All fields are required.");
      return;
    }

    try {
      const data = await addQuiz(
        type,
        question,
        options,
        correctAnswer,
        reward,
        type === "Trading" ? bot : undefined
      );
      console.log(data, "this is data");

      toast.success("Quiz added successfully!");
    } catch (error) {
      console.error("Error message:", error);
      toast.success("Quiz added successfully!");
    }
    setloading(false)
    goTo("/quiz");
  };


  return (
    <>
      {loading ? (

        <ScreenLoader />

      ) : (
        <>
          <Loading disp={isLoading} />
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={isOpenSnackbar}
            autoHideDuration={6000}
            onClose={() => {
              setIsOpenSnackbar(false);
            }}
          >
            <Alert
              onClose={() => {
                setIsOpenSnackbar(false);
              }}
              severity={snackbarStatus}
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>
          {/* <Layout> */}
          <div style={{ backgroundColor: "#101214" }}>
            <div className="edit-task-page">
              <div className="edit-task-page-header">
                <h2
                  style={{
                    fontFamily: "Montserrat-Bold",
                    fontSize: "24px",
                    fontWeight: "700",
                    color: "#F4F5F7",
                    lineHeight: "29.05px",
                  }}
                >
                  Quiz
                </h2>
              </div>
              <div
                style={{
                  border: "2px solid #121212",
                  borderRadius: "10px",
                  marginTop: "24px",
                }}
              >
                <div className="edit-task-page-body">
                  <div className="edit-task-page-body-left">
                    <div style={{ marginBottom: "30px" }}>
                      <label
                        style={{
                          fontFamily: "Montserrat-Bold",
                          fontSize: "16px",
                          fontWeight: "700",
                          color: "#FFFFFF",
                          lineHeight: "19.5px",
                        }}
                        htmlFor="quiz_type"
                      >
                        Choose Quiz Type
                      </label>
                      <Select
                        id="quiz_type"
                        value={type}
                        onChange={(e) => {
                          setType(e.target.value);
                        }}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        sx={{
                          color: "white",
                          ".MuiSelect-icon": { color: "white" },
                          backgroundColor: "#333",
                        }}
                      >
                        <MenuItem
                          value="Education"
                          style={{
                            fontFamily: "Montserrat-Semibold",
                            fontSize: "16px",
                            fontWeight: "500",
                            color: "#FFFFFF",
                            lineHeight: "19.5px",
                            background: "transparent",
                            borderBottom: "1px solid #50535B",
                          }}
                        >
                          Education
                        </MenuItem>
                        <MenuItem
                          value="Trading"
                          style={{
                            fontFamily: "Montserrat-Semibold",
                            fontSize: "16px",
                            fontWeight: "500",
                            color: "#FFFFFF",
                            lineHeight: "19.5px",
                            background: "transparent",
                          }}
                        >
                          Trading
                        </MenuItem>
                      </Select>
                    </div>

                    {type === "Trading" && (
                      <div style={{ marginBottom: "30px" }}>
                        <label
                          style={{
                            fontFamily: "Montserrat-Bold",
                            fontSize: "16px",
                            fontWeight: "700",
                            color: "#FFFFFF",
                            lineHeight: "19.5px",
                          }}
                          htmlFor="bot_type"
                        >
                          Choose Bot Type
                        </label>
                        <Select
                          id="bot_type"
                          value={bot}
                          onChange={(e) => {
                            setBot(e.target.value);
                          }}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          sx={{
                            color: "white",
                            ".MuiSelect-icon": { color: "white" },
                            backgroundColor: "#333",
                          }}
                        >
                          <MenuItem
                            value="T-800"
                            style={{
                              fontFamily: "Montserrat-Semibold",
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#FFFFFF",
                              lineHeight: "19.5px",
                              background: "transparent",
                              borderBottom: "1px solid #50535B",
                            }}
                          >
                            T-800
                          </MenuItem>
                          <MenuItem
                            value="T-1000"
                            style={{
                              fontFamily: "Montserrat-Semibold",
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#FFFFFF",
                              lineHeight: "19.5px",
                              background: "transparent",
                              borderBottom: "1px solid #50535B",
                            }}
                          >
                            T-1000
                          </MenuItem>
                          <MenuItem
                            value="T-X"
                            style={{
                              fontFamily: "Montserrat-Semibold",
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#FFFFFF",
                              lineHeight: "19.5px",
                              background: "transparent",
                            }}
                          >
                            T-X
                          </MenuItem>
                        </Select>
                      </div>
                    )}
                    <div style={{ marginBottom: "30px" }}>
                      <label
                        style={{
                          fontFamily: "Montserrat-Bold",
                          fontSize: "16px",
                          fontWeight: "700",
                          color: "#FFFFFF",
                          lineHeight: "19.5px",
                        }}
                        htmlFor="task_title"
                      >
                        Question
                      </label>
                      <input
                        type="text"
                        className="task_title"
                        value={question}
                        onChange={(e) => setQuestion(e.target.value)}
                        name="task_title"
                        placeholder="Enter Question"
                      />
                    </div>

                    {options.map((option, index) => (
                      <div key={index} style={{ marginBottom: "33px" }}>
                        <label
                          style={{
                            fontFamily: "Montserrat-Bold",
                            fontSize: "16px",
                            fontWeight: "700",
                            color: "#FFFFFF",
                            lineHeight: "19.5px",
                          }}
                          htmlFor={`option${String.fromCharCode(65 + index)}`}
                        >
                          Option {String.fromCharCode(65 + index)}
                        </label>
                        <input
                          className="input-number1"
                          type="text"
                          value={option}
                          onChange={(e) =>
                            handleOptionChange(index, e.target.value)
                          }
                          id={`option${String.fromCharCode(65 + index)}`}
                          name={`option${String.fromCharCode(65 + index)}`}
                          placeholder={`Enter Option ${String.fromCharCode(
                            65 + index
                          )}`}
                        />
                      </div>
                    ))}

                    <div style={{ marginBottom: "33px" }}>
                      <label
                        style={{
                          fontFamily: "Montserrat-Bold",
                          fontSize: "16px",
                          fontWeight: "700",
                          color: "#FFFFFF",
                          lineHeight: "19.5px",
                        }}
                        htmlFor="task_group"
                      >
                        Choose Correct Answer
                      </label>
                      <Select
                        id="task_group"
                        value={correctAnswer}
                        onChange={(e) => {
                          const selectedIndex = e.target.value;
                          setCorrectAnswer(options[selectedIndex]);
                        }}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        sx={{
                          color: "white",
                          ".MuiSelect-icon": { color: "white" },
                          backgroundColor: "#333",
                        }}
                      >
                        {options.map((option, index) => (
                          <MenuItem
                            key={index}
                            value={index}
                            style={{
                              fontFamily: "Montserrat-Semibold",
                              fontSize: "16px",
                              fontWeight: "500",
                              color: "#FFFFFF",
                              lineHeight: "19.5px",
                              background: "transparent",
                              borderBottom: "1px solid #50535B",
                            }}
                          >
                            {String.fromCharCode(65 + index)}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>

                    <div style={{ marginBottom: "36px" }}>
                      <label
                        style={{
                          fontFamily: "Montserrat-Bold",
                          fontSize: "16px",
                          fontWeight: "700",
                          color: "#FFFFFF",
                          lineHeight: "19.5px",
                        }}
                        htmlFor="reward_amount"
                      >
                        Amount of $SKAI reward
                      </label>
                      <input
                        className="input-number1"
                        type="number"
                        id="reward_amount"
                        value={isEditing ? reward : reward === 0 ? "" : reward}
                        onFocus={() => setIsEditing(true)}
                        onBlur={() => {
                          setIsEditing(false);
                          if (reward === "") setReward(0);
                        }}
                        onChange={(e) => {
                          const value = parseFloat(e.target.value);
                          setReward(isNaN(value) ? 0 : value);
                        }}
                        name="reward_amount"
                        placeholder="Enter $SKAI reward"
                      />
                    </div>
                    <div className="edit-page-left-button-group">
                      <Button
                        variant="contained"
                        onClick={handleAddQuiz}
                        disableElevation
                      >
                        {taskId.length ? "Publish" : "Publish"}
                      </Button>
                      <Button variant="outlined" onClick={handleCancelButtonClick}>
                        Cancel
                      </Button>
                    </div>
                    {/* <br /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </Layout> */}
        </>
      )}
    </>
  );
};

export default EditTaskPage;
